import AOS from 'aos';

$(document).ready(() => {
    AOS.init({
        disable: window.innerWidth < 1025,
        once: true,
        duration: 1000
    });
});

$(window).on('load', () => {
    AOS.init();
});
