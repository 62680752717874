$(document).ready(() => {
    if ($("html").hasClass("no-object-fit")){
        $('.home-gallery ul li , .overlay-img-link a p , .slide').addClass('img-fit');
    }
    $('.no-object-fit .img-fit').each(function () {
      var $container = $(this),
          imgUrl = $container.find('img').prop('src');
      if (imgUrl) {
        $container
          .css('backgroundImage', 'url(' + imgUrl + ')')
          .addClass('compat-object-fit');
      }
    });
});
