import 'magnific-popup';

const popupGallery = () => {
    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1], // Will preload 0 - before current, and 1 after the current image
            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"><i class="fa fa-angle-%dir% mfp-prevent-close"></i></span>',
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });
}

const popupThumbs = () => {
    $('ul.thumbs').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1], // Will preload 0 - before current, and 1 after the current image
            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"><i class="fa fa-angle-%dir% mfp-prevent-close"></i></span>',
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
                var caption = 
                    '<div id="gallery-caption">';
                    if (item.el.attr('title') != undefined) {
                        caption += item.el.attr('title');
                    }
                    caption += '</div>';
                    if (item.el.attr('gallery-credit') != undefined) {
                        caption += '<div id="gallery-credit">';
                        caption += item.el.data('credit');
                        caption += '</div>';
                    }
            }
        }
    });
}

$(document).ready(() => {
    popupGallery();
    popupThumbs();
});
